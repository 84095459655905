
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import { ObjectEx, parseTime } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { getSearchType, userList, userListExcel } from "@/api/request/player";

//组件
@Component({
  name: "Player",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //时间参数
  get pickerOptions() {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //临时数据
    useridStr: "",
    unionidStr: "",
    time: [] as any,

    //请求数据
    userid: 0,
    unionid: 0,
    country: "",
    username: "",
    endformat: "",
    startformat: "",

    //是否导出
    excel: false,
  };

  //定义变量
  private list: any[] = [];
  private ipList: any = {};
  private total: number = 0;
  private unionList: any = {};
  private ipInfoList: any = {};
  private countryList: any[] = [];
  private listLoading: boolean = true; //列表加载

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取查询类型列表
    this.getSearchTypeList();

    //获取数据
    this.getList();
  }

  ////获取数据
  private async getList() {
    //显示等待数据
    this.listLoading = true;

    //获取数据
    const { data } = await userList(this.getListQuery());

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.ipList = ObjectEx.merge(this.ipList, data.ips);
    this.ipInfoList = ObjectEx.merge(this.ipInfoList, data.country);

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理EXCEL
  private async handleExcel() {
    //显示等待数据
    this.listLoading = true;

    //获取数据
    const { data } = await userListExcel(this.getListQuery(true));

    //保存文件
    this.listQuery.time.length ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}会员列表`) : saveAs(data, "会员列表总览");

    //隐藏等待
    this.listLoading = false;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.excel = false;

    //获取数据
    this.getList();
  }

  //获取查询类型列表
  private async getSearchTypeList() {
    //数据赋值
    this.listLoading = true;

    //获取数据
    const { data } = await getSearchType({});

    //数据赋值
    const froms = GFunc.checktable(GFunc.checktable(data.types).guildFrom);

    //设置工会列表
    this.setSearchChannelUnionList(froms);
  }

  //获取查询条件数据
  private getListQuery(isExport?: boolean): any {
    //是否导出
    const data = GFunc.deepClone(this.listQuery);

    //时间数据赋值
    data.time = GFunc.checkarray(data.time);
    data.endformat = DTCls.getEndTimeFormat(data.time[1]);
    data.startformat = DTCls.getStartTimeFormat(data.time[0]);

    //用户数据赋值
    data.username = data.username.trim();
    data.userid = GFunc.checkint(data.useridStr);
    data.unionid = GFunc.checkint(data.unionidStr);

    //是否导出
    data.excel = isExport;

    //返回数据
    return data;
  }

  //设置工会列表
  private setSearchChannelUnionList(froms: any) {
    //数据赋值
    const arr = Object.keys(froms);
    for (let idx = 0; idx < arr.length; idx++) {
      const unionId = arr[idx];
      const unionName = froms[unionId];
      this.unionList[unionId] = unionName;
    }
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //----------------------------------------- 数据处理 -----------------------------------------
  //性别
  private getSexName(sex: number): string {
    //数据赋值
    let name = "--";
    switch (sex) {
      case 0:
        name = "保密";
        break;
      case 1:
        name = "男";
        break;
      case 2:
        name = "女";
        break;
    }

    //返回结果
    return name;
  }

  //获取时间
  private getTimeString(time: any): string {
    //数据赋值
    let str = parseTime(time);
    if (str === null) {
      str = "0000-00-00 00:00:00";
    }

    //返回结果
    return str;
  }

  //IP数量
  private findIpAmount(ip: string): number {
    //数据赋值
    ip = GFunc.checkstring(ip);
    let t = 0;
    if (ip) {
      t = this.ipList[ip] || 1;
    }

    //返回结果
    return t;
  }

  //坐标
  private getCityName(city: string): string {
    //城市名称
    city = GFunc.checkstring(city);

    //返回结果
    return !city ? "--" : city;
  }

  //休息模式
  private getSleepName(type: number): string {
    //数据赋值
    let name = "--";
    switch (type) {
      case 1:
        name = "开启";
        break;
      case 3:
        name = "关闭";
        break;
    }

    //返回结果
    return name;
  }

  //公会D
  private getUnionID(unionId: number): string {
    return unionId > 0 ? unionId.toString() : "--";
  }

  //公会名称
  private getUnionName(unionId: number): string {
    return this.unionList[unionId] || "--";
  }

  //账号状态
  private getAccStatusName(status: number): string {
    let name = "--";
    switch (status) {
      case 0:
        name = "封停";
        break;
      case 1:
        name = "正常";
        break;
      case 2:
        name = "未验证";
        break;
    }
    return name;
  }
}
